import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
import { useEffect, useMemo, useRef } from "react";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useGetTechicalDocuments } from "src/Utils/Hooks/Downloads/useGetTechicalDocuments";
import DocumentsDetails from "../Documents/DocumentsDetails";
import Loader from "src/Components/Loader/Loader";
interface ITechnicalDocumentsParams {
    setInfo: (message: string, type: InfoMessageType) => void;
}

const TechnicalDocuments: React.FC<ITechnicalDocumentsParams> = ({setInfo }) => {
    const { data, loading, error } = useGetTechicalDocuments()
    const removeDocs = useRef<string[]>(['Aura Troubleshooting Guide']);
    const filteredList = useMemo(() => data &&  data.filter(doc => !removeDocs.current.includes(doc.displayName)), [data])
    useEffect(() => {
        if (error) {
            logger.logError(`Failed to get techinical documents.`)
            setInfo(error, InfoMessageType.error);
        }
    }, [error, setInfo])
    return (
        <>
            {loading && <Loader />}
            {filteredList &&
               <DocumentsDetails data={filteredList} title="Technical Documentation" id="tech-doc"/>
            }
        </>
    )
}
export default TechnicalDocuments;